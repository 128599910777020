import React from 'react';
import { services } from '../../Data';
import { FaArrowRight } from "react-icons/fa";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import shapeImgOne from "../../assets/shape-1.png";
import shapeImgTwo from "../../assets/shape-2.png";
import "./style.scss";
import { Link } from 'react-scroll';

const Services = () => {
  return (
    <>
      <section className="services section" id='services'>
        <h2 className="section__title text-cs">What I Do</h2>
        <p className="section__subtitle">
          My <span>Services</span>
        </p>
        <Swiper
        spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            540: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          modules={[Pagination]} className="services__container container mySwiper">
          {services.map(({ name, title, description }, index) => {
            return (
              <SwiperSlide className="services__item card card-one" key={index}>
                <span className="services__subtitle text-cs">{name}</span>
                <h3 className="services__title">{title}</h3>
                <p className="services__description">{description}</p>

                <Link activeClass="active" className='link' to='plans' spy={true} hashSpy={true} smooth={true} offset={-150} duration={1000}>
                  See Plans
                  <FaArrowRight className='link__icon'></FaArrowRight>
                </Link>

                <img src={shapeImgTwo} alt="shapeImg2" className='shape c__shape' />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="section__deco deco__right">
            <img src={shapeImgOne} alt="shape_1" className='shape' />
          </div>

          <div className="section__bg-wrapper">
          <span className="bg__title">Service</span>
        </div>
      </section>
    </>
  )
}

export default Services;